import React, { FunctionComponent } from "react";

import { GlobalStyles } from "twin.macro"
import { css } from '@emotion/css'

const tailwindStyle = css`
    @tailwind base;
    @tailwind components;
    @tailwind utilities;
`;

const OrderSummaryStyle = css`
`;


const composedStyle = css`
    ${tailwindStyle};
    ${OrderSummaryStyle};
`;

interface OrderSummaryProps
{
    courseName: string,
    courseCost: number
}

export const OrderSummary: FunctionComponent<OrderSummaryProps> = props => {

    return (
        <>
        <div className={composedStyle}>
            <GlobalStyles />
            <div tw="flex font-NewsGothic font-bold justify-between">
            <div>Your order</div><div>Amount</div>
            </div>
            <hr/>
            <div tw="flex font-Xpress justify-between">
            <div>{props.courseName}</div><div>${props.courseCost}</div>
            </div>
        </div>
        </>
    )
}
