

/*
This function calls an API that stores name, email and a key that identifies payment.

Currently, strip generates this clientSecret, but this does not ALWAYS have to be the case.


*/

enum Environment
{
    Dev = "DEV",
    Build = "BUILD",
    QA = "QA",
    Prod = "PROD"
}


export function StoreCheckoutInfo(env :Environment, targetName: string, targetEmail: string,
    targetYear: string, targetMonth: string, targetDay: string, targetHour: string,
    targetMinute: string, targetSecond: string, targetMilliseconds: string, ISOString: string){

    const api_url = 'https://addcustomerinfov1-tgcg47zmfa-uc.a.run.app';

    const parameters = {
        fullname: targetName,
        email: targetEmail,
        updateyear: targetYear,
        updatemonth: targetMonth,
        updateday: targetDay,
        updatehour: targetHour,
        updateminute: targetMinute,
        updatesecond: targetSecond,
        updatemilliseconds: targetMilliseconds,
        isostring: ISOString
    };

    // console.log("Store checkout info - parameters", parameters);
    // ACCESS To fetch has been blocked by cors

    try {
    fetch(api_url, {
        method: 'POST', 
        mode: 'cors',
        cache: 'no-cache',
        referrerPolicy: 'no-referrer-when-downgrade',
        headers: {
        'Content-Type': 'application/json'
        },
        body: JSON.stringify(parameters),
        keepalive: false

    }).then(response => {

        // TODO - remove all of this when tested
        response.json().then(data => {
        if(response.status == 201)
        {
            //console.log("StoreCheckout success", response)
            // GOTO success page
        } else
        {
            //console.log("StoreCheckout api error")
            // TODO - set the error
            //setApiError(data.outputtext)
        }
        })
    });
    } catch (err) {
    console.log("StoreCheckout: Some other error happened in the try block", err)
    // TODO set the error
    //setApiError(err)
    }


}