
// Take the values state object and determine if there are any errors

import { optionalErrors } from "./types"

export function validateEmail(values) {
    let errors: optionalErrors = {}

    // Email errors

    // TODO - Guessing this must depend on validate
    // Not sure though why the state would have errors
    if(!values.email) {
        errors.email = "Email required"
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = "Invalid email address"
    }

    return errors;
}