import React, { useEffect, useState, FunctionComponent } from "react";

import {
    PaymentElement,
    useStripe,
    useElements
  } from "@stripe/react-stripe-js";

import { GlobalStyles } from "twin.macro"
import { css } from '@emotion/css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck} from '@fortawesome/free-solid-svg-icons'

import { SiteURL } from "../../common/sitemeta/siteurl";
import { StoreCheckoutInfo } from "./StoreCheckoutInfo";
import { OrderSummary } from "./OrderSummary";

const tailwindStyle = css`
    @tailwind base;
    @tailwind components;
    @tailwind utilities;
`;

const PaymentFormStyle = css`

h3 {
        font-size: 2.8rem;
        font-weight: 700;
        font-family: "NewsGothic";
    }

    body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
}

form {

  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}


#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}


/* Buttons and links */
button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

button:hover {
  filter: contrast(115%);
}

button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
`;

const composedStyle = css`
    ${tailwindStyle};
    ${PaymentFormStyle};
`;

// Keep track of the payments, show errors, manage the UI

interface CheckoutFormProps {
  fullName: string,
  email: string,
  location: any
}

// TODO - Environment is declared twice - should only be declared once
enum Environment
{
    Dev = "DEV",
    Build = "BUILD",
    QA = "QA",
    Prod = "PROD"
}

export const CheckoutForm: FunctionComponent<CheckoutFormProps> = props => {

    // Custom stripe hooks seem to only work in an elements context
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState<null | string>(null);
    const [isLoading, setIsLoading] = useState(false);

    const location = props.location;

    // This effect is dependent on stripe being present
    useEffect(() => {
        if (!stripe) {
          return;
        }

      //   const clientSecret = new URLSearchParams(window.location.search).get(
      //     "payment_intent_client_secret"
      // );



        // This should be stuff to right of ?
        const clientSecret = new URLSearchParams(location.search).get(
            "payment_intent_client_secret"
        );

        // TODO - remove this
        //console.log("payment intent client secret", clientSecret);

        if (!clientSecret){
            return;
        }

        // retrieve the intent with the secret
        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            if(paymentIntent)
            {
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
            }
            else{
                setMessage("Processing is not working.");
            }
        });
    }, [stripe]);    

    // Feels like there is a full form and the final button is the Buy now $20 button

    // RAB start here - https://stripe.com/docs/payments/quickstart

    const handleSubmit = async (e, siteURL: string, fullName: string, email: string) => {
        e.preventDefault();
        console.log("INSIDE handleSubmit");

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
          }
        
          setIsLoading(true);

          // TODO - create a custom purchase page (with corresponding tag) that is part of whiteowl
          // TODO ERR - make sure this custom purcahse page does not spill over on margins

          // https://stripe.com/docs/payments/accept-a-payment?platform=web&ui=elements&html-or-react=react
          // AFter the customer provides billing details, confirm payment is called. 

          // const returnURL = "http://localhost:8000/order/thankyou"
          const returnURL = siteURL + "order/thankyou"

          

          
          // TODO: StoreCheckoutInfo is only needed to store OUR customer information for future use
          // StoreCheckoutInfo("PROD" as Environment, fullName, email);

          // some payment methods require address
          // some payment methods require phone
          console.log("Return URL: ", returnURL);

          const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
              receipt_email: email,
              return_url: returnURL,
              payment_method_data: {
                billing_details: {
                  name: fullName
                }
              }
            },
          });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
            if(error.message){
                setMessage(error.message);
            }
        } else {
            console.log("Unexpected error is occurring", error);
            setMessage("An unexpected error occurred.");
        }
            
    }

    // This is sent to handle submit
    const siteURL = SiteURL();

    // TODO: There is an optional part involving getting updates from the server

    // TODO: There must be validation of full name and email before submission of the form
    // TODO: do not allow submission if full name and email is not there
    // TODO: right now the process has the customer to opt out of email - it should be opt in by default

    // BEFORE PAYMENT ELEMENT CHANGE

    return(
        <div className={composedStyle}>
            <GlobalStyles />
            
            <form id="payment-form" onSubmit={(e) => handleSubmit(e, siteURL, props.fullName, 
                                                                  props.email)}>  
                <PaymentElement id="payment-element" />
                <OrderSummary
                  courseName="Data Visualization Course"
                  courseCost={20}
                />

                <button disabled={isLoading || !stripe || !elements} id="submit" tw="mt-6">
                    <span id="button-text" >
                        {isLoading ? <div className="spinner" id="spinner"></div> : 
                          <div>
                          <FontAwesomeIcon icon={faCheck} size="lg" color="white" />
                          <span >&nbsp;&nbsp;&nbsp;</span>
                          <span>Yes, send me the course!</span>
                          </div>
                        }
                    </span>
                </button>
                {/* Show any error or success messages */}
                {message && 
                    <div tw="text-red-600 text-xl" id="payment-message">{message}</div>
                }
            </form>
        </div>
    );
}

