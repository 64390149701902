import React, { FunctionComponent } from "react";

import { GlobalStyles } from "twin.macro"
import { css } from '@emotion/css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck} from '@fortawesome/free-solid-svg-icons'

import { validateEmail } from '../../common/forms/validateEmail';
// import useEmailValidation from '../../common/forms/useEmailValidation';

const tailwindStyle = css`
    @tailwind base;
    @tailwind components;
    @tailwind utilities;
`;

const EmailBoxStyle = css`

:root {
    --borderRadius: 5px;
    --colorBackground: #fff;
    --fontSizeSm: 0.93rem;
    --p-spacing1: 0.25rem;
    --p-spacing3: 0.75rem;
    --p-colorBackgroundDeemphasize10: #e6e6e6;
}

form {
webkit-align-self: center;
ms-flex-item-align: center;
align-self: center;
box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
  0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
border-radius: 7px;
padding: 40px;
}

label {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    display: block;
    margin-bottom: 0.25rem;
    font-size: 0.93rem;
    font-weight: 400;
    transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);

}

input {
    padding: 0.75rem;
    padding-right:51.2px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
    border: 1px solid #e6e6e6;
    display: block;
    width: 100%;

}

/* Buttons and links */
button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

button:hover {
  filter: contrast(115%);
}



`;

const composedStyle = css`
    ${tailwindStyle};
    ${EmailBoxStyle};
`;


// Parent component as sourt of truth

// Parameters
// firstName: string
// onFirstNameChange(name: string)
// email: string
// onEmailChange(email: string)

type StringChangeEventHandler = (data: string) => void;

// Email Form Handler will just change tab
type EmailFormHandler = () => void;



interface EmailBoxProps {
    fullName: string,
    onFullNameChange: StringChangeEventHandler,
    email: string,
    onEmailChange: StringChangeEventHandler,
    onFormSubmit: EmailFormHandler,
    emailErrorMessage: null | undefined | string,
    displayErrors: boolean

}



export const EmailBox: FunctionComponent<EmailBoxProps> = props => {

// export default function EmailBox() {

    let fullName = props.fullName;
    let email = props.email;
    let emailErrorMessage = props.emailErrorMessage ? props.emailErrorMessage : "";
    let displayErrors = props.displayErrors;


    const handleSubmit = (event) => {
        event.preventDefault();
        props.onFormSubmit();
        // TODO - on submit go to the next tab
    }



    return(
        <div className={composedStyle}>
            <GlobalStyles />
            <form onSubmit={handleSubmit}>
                <label htmlFor="fullname">First and Last Name</label>
                <input
                id="fullname"
                type="text"
                className="input"
                placeholder="John Doe"
                value={fullName}
                onChange={(event) => props.onFullNameChange(event.target.value)}
                />
                <label htmlFor="emailaddress">Email Address</label>
                <input 
                id="emailaddress"
                type="email"
                className='input' 
                placeholder="your@email.com"
                value={email}
                onChange={(event) => props.onEmailChange(event.target.value)}
                />
                {emailErrorMessage && displayErrors &&
                    <div tw="text-red-600 text-xl" id="email-error-message">{emailErrorMessage}</div>
                }
                <button  id="submit" tw="mt-6 ">
                    <FontAwesomeIcon icon={faCheck} size="lg" color="white" />
                    <span >&nbsp;&nbsp;&nbsp;</span>
                    <span id="button-text">
                        Yes, send me course info!
                    </span>
                </button>
                <p id="privacy" tw="font-Xpress text-sm mt-2 text-center">We Respect Your Privacy and Information</p>
            </form>
            
        </div>
    );
}