

export function SiteURL() {

    let siteURL = "";

    const localURL = `${process.env.GATSBY_SITE_URL}`;
    if(localURL && localURL.length != 0 )
    {
        siteURL = localURL
    } else {
        // TODO LOW fix this hard coding
        siteURL = "https://www.whiteowleducation.com/"
    }

    //console.log("SiteURL function: ", siteURL);
    return siteURL;

}
